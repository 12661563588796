import React from "react";
import Img from "gatsby-image";
import { graphql, Link } from "gatsby";
import classnames from "classnames";

import Layout from "../components/layout";
import Pagination from "../components/pagination";
import PageTitle from "../components/pagetitle";

import workStyles from "./work.module.css";

const HeroImageSlice = ({ hero_image, background_color }) => (
  <section style={{ backgroundColor: background_color }}>
    <div className="mx-auto py-8 md:py-16 max-w-5xl px-2">
      <Img fluid={hero_image.fluid} alt={hero_image.alt} className="w-full" />
    </div>
  </section>
);

const RichTextSlice = ({ richtext }) => (
  <section
    dangerouslySetInnerHTML={{ __html: richtext.html }}
    className={`${workStyles.work} max-w-2xl mx-auto py-10 text-center`}
  />
);

const WorkTemplate = ({ data, pageContext }) => {
  const {
    title,
    description,
    scope_items,
    client_link,
    body
  } = data.prismicWork.data;
  const {
    description_header,
    scope_header,
    all_work_text
  } = data.prismicWorkDetail.data;

  const { next, prev } = pageContext;
  const nextProps = next
    ? {
        url: `/work/${next.uid}`,
        image: next.data.image,
        text: next.data.title.text
      }
    : null;
  const prevProps = prev
    ? {
        url: `/work/${prev.uid}`,
        image: prev.data.image,
        text: prev.data.title.text
      }
    : null;

  return (
    <Layout>
      <div className="pb-24 pt-10">
        <PageTitle text={title.text} />
        <section className="flex flex-wrap justify-between max-w-5xl mx-auto my-10 w-full px-4">
          <div className="md:max-w-2xl mb-6">
            <h2 className="font-serif text-2xl lg:text-4xl mb-4">
              {description_header}
            </h2>
            <div
              dangerouslySetInnerHTML={{ __html: description.html }}
              className="pb-4"
            />
            <a
              href={client_link.url}
              target="_blank"
              className="underline hover:no-underline"
            >
              {client_link.url}
            </a>
          </div>
          <div>
            <h3 className="text-2xl mb-6">{scope_header}</h3>
            <ul>
              {scope_items.map(({ scope_item }, index) => (
                <li key={index} className="mb-2">
                  {scope_item}
                </li>
              ))}
            </ul>
          </div>
        </section>
        {body.map(({ slice_type, primary }, index) => {
          if (slice_type === "hero_image") {
            return <HeroImageSlice key={index} {...primary} />;
          } else if (slice_type === "richtext") {
            return <RichTextSlice key={index} {...primary} />;
          }
        })}
        <Pagination next={nextProps} prev={prevProps} />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query WorkPageByUid($uid: String!) {
    prismicWorkDetail {
      data {
        description_header
        scope_header
        all_work_text
      }
    }
    prismicWork(uid: { eq: $uid }) {
      data {
        title {
          text
        }
        description {
          html
        }
        scope_items {
          scope_item
        }
        client_link {
          url
        }
        body {
          ... on PrismicWorkBodyHeroImage {
            primary {
              background_color
              hero_image {
                fluid(maxWidth: 1024) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
            slice_type
          }
          ... on PrismicWorkBodyRichtext {
            primary {
              richtext {
                html
              }
            }
            slice_type
          }
        }
      }
    }
  }
`;

export default WorkTemplate;
