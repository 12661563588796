import React from "react";
import PropTypes from "prop-types";

const sharedStyles = "font-serif leading-none";

const PageTitle = ({ text, hValue }) => (
  <>
    {hValue === "h1" ||
      (!hValue && (
        <h1 className={`${sharedStyles} text-3xl px-4 md:text-6xl text-center`}>
          {text}
        </h1>
      ))}
    {hValue === "h2" && (
      <h2 className={`${sharedStyles} text-2xl md:text-5xl`}>{text}</h2>
    )}
    {hValue === "h3" && (
      <h3 className={`${sharedStyles} text-2xl md:text-4xl`}>{text}</h3>
    )}
    {hValue === "h4" && (
      <h4 className={`${sharedStyles} text-2xl md:text-3xl`}>{text}</h4>
    )}
  </>
);

export default PageTitle;

PageTitle.propTypes = {
  hValue: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "h6"]),
  text: PropTypes.string.isRequired
};
