import React from "react";
import { DIRECTION } from "../constants/helpers";

import PaginationCard from "../components/pagination-card";

const Pagination = ({ prev, next }) => (
  <div className="flex items-start justify-between max-w-6xl mx-auto mt-10 border-t pt-8 w-full px-4">
    {prev && (
      <PaginationCard
        {...prev}
        direction={DIRECTION.PREV}
        classNames="mr-auto"
      />
    )}
    {next && (
      <PaginationCard
        {...next}
        direction={DIRECTION.NEXT}
        classNames="ml-auto"
      />
    )}
  </div>
);

export default Pagination;
