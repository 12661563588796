import React from "react";
import Img from "gatsby-image";
import { Link } from "gatsby";
import classnames from "classnames";
import Moment from "react-moment";

import { DIRECTION } from "../constants/helpers.js";

import prevArrow from "../images/icons/prev.svg";
import nextArrow from "../images/icons/next.svg";

const PaginationCard = ({ text, image, url, direction, classNames, date }) => (
  <Link
    to={url}
    className={classnames(`flex flex-col group text-center ${classNames}`)}
  >
    {date && <Moment format="MMM DD, YYYY">{date}</Moment>}
    <Img
      fluid={{ ...image.fluid, aspectRatio: 1.75 }}
      alt={text}
      className="w-32 md:w-48 mx-auto object-contain border rounded-sm"
    />
    <div className="flex items-center justify-center">
      {direction === DIRECTION.PREV && (
        <img
          src={prevArrow}
          className="mr-3 h-2 group-hover:mr-4"
          alt="arrow"
        />
      )}

      <h5 className="text-lg md:text-xl my-4 leading-none">{text}</h5>

      {direction === DIRECTION.NEXT && (
        <img
          src={nextArrow}
          className="ml-3 h-2 group-hover:ml-4"
          alt="arrow"
        />
      )}
    </div>
  </Link>
);

export default PaginationCard;
